<template>
  <f7-page class="ordercheckout-page" @page:beforein="callCheck">
    <template #fixed>
      <ProductNavigationComponent :title="$t.getTranslation('LBL_ORDER_CHECKOUT')" type="back" search="false" cart="false" />

      <f7-toolbar tabbar labels position="bottom" class="checkout-toolbar">
        <h4 @click="openTotalInfo">
          <font-awesome-icon :icon="['fas', 'info-circle']" fixed-width />
          <strong>{{ $t.getTranslation('LBL_TOTAL') }}:</strong>
          {{ helpers.formatPrice(Order.GrandTotal, Order.CurrencyCode) }}
        </h4>
        <f7-link class="fill" href="javascript:;" text="Scan" @click="orderCheckout"> {{ $t.getTranslation('LBL_CONFIRM') }} </f7-link>
      </f7-toolbar>
    </template>

    <section class="ordercheckout-container">
      <div class="container">
        <div class="title">
          <h1>{{ $t.getTranslation('LBL_DELIVERY_ADDRESS') }}</h1>
        </div>

        <div class="delivery-address-container" style="min-height: 100px">
          <div class="delivery-add" @click="addAddress">
            <font-awesome-icon :icon="['far', 'plus']" fixed-width />
          </div>

          <f7-swiper :slides-per-view="'auto'" :space-between="10">
            <f7-swiper-slide v-for="(add, i) in Address" :key="'dlad' + i" class="delivery-address" :class="{ active: UserAddressKey == add.UserAddressKey }">
              <font-awesome-icon class="delivery-setting" :icon="['far', 'ellipsis-v']" fixed-width @click="openActions(add)" />

              <div @click="selectAdd(add)">
                <p>{{ add.PrimaryAddress }}, {{ add.District }}, {{ add.City }}, {{ add.Province }}</p>

                <span class="delivery-active">
                  <span v-if="add.IsDefault">{{ $t.getTranslation('LBL_DEFAULT_ADDRESS') }}</span>

                  <font-awesome-icon v-if="UserAddressKey == add.UserAddressKey" :icon="['fas', 'check-circle']" fixed-width />
                </span>
              </div>
            </f7-swiper-slide>
          </f7-swiper>
        </div>

        <div class="title">
          <h1>{{ $t.getTranslation('LBL_ORDER_SUMMARY') }}</h1>
        </div>

        <div v-for="(product, image) in Order.CheckoutItemList" :key="'pr_' + image" class="product-container">
          <div class="orderproductitem" style="background-color: #fff; padding: 10px">
            <div class="image" style="width:100px;">
              <div class="prod-image-disp" :style="{ width: '100px', height: '100px', 'background-image': 'url(' + helpers.getImage(product.PrimaryImage, 'PRODUCT') + ')' }"></div>
            </div>
            <div class="content" style="width: calc(100% - 100px);">
              <h3>{{ helpers.getAttribute(product, $t.getLanguage(), 'Name') }}</h3>
              <div v-if="product?.PackingQty?.Total"><font-awesome-icon :icon="['fal', 'wine-bottle']" fixed-width /> x {{ product.PackingQty.Total }}</div>
              <h4>{{ $t.getTranslation('LBL_QUANTITY') }}: {{ product.Qty }}</h4>
            </div>
          </div>
        </div>

        <div class="title" v-if="SplitOrderCounter == 0">
          <h1>{{ $t.getTranslation('LBL_PAYMENT_METHOD') }}</h1>
        </div>

        <ul class="paymentmethods" v-if="SplitOrderCounter == 0">
          <li v-for="payment in PaymentMethodList" :key="payment.key" class="payment" :class="{ active: PaymentMethod == payment.key }" @click="selectPayment(payment.key)">
            <div class="disp-flex">
              <font-awesome-icon :icon="payment.icon" fixed-width />
              <h1>{{ payment.label }}</h1>
            </div>

            <span v-if="PaymentMethod == payment.key" class="default-payment">
              <font-awesome-icon :icon="['fas', 'check-circle']" fixed-width />
              {{ $t.getTranslation('LBL_SELECTED_PAYMENT') }}
            </span>
          </li>
        </ul>
      </div>
    </section>

    <f7-sheet class="orderTotalInfo" swipe-to-close backdrop>
      <f7-page-content>
        <div class="title">
          <h1>{{ $t.getTranslation('LBL_SUMMARY') }}</h1>
        </div>

        <div class="cart-info">
          <div class="content">
            <h3>{{ $t.getTranslation('LBL_TOTAL_QUANTITY') }}</h3>
            <h3>{{ Order.TotalItems }} {{ $t.getTranslation('LBL_UNITS') }}</h3>
          </div>

          <div class="content">
            <h3>{{ $t.getTranslation('LBL_TOTAL_BOTTLES') }}</h3>
            <h3>{{ (typeof Order.TotalBottles !== 'undefined' && Order.TotalBottles) || '---' }} {{ $t.getTranslation('LBL_PCS') }}</h3>
          </div>

          <div class="content">
            <h3>{{ $t.getTranslation('LBL_SUB_TOTAL') }}</h3>
            <h3>{{ helpers.formatPrice(Order.TotalPrice, Order.CurrencyCode) }}</h3>
          </div>

          <div class="content">
            <h3>{{ $t.getTranslation('LBL_SHIPPING_FEE') }}</h3>
            <h3>{{ helpers.formatPrice(Order.TotalShipping, Order.CurrencyCode) }}</h3>
          </div>

          <div class="content">
            <h3>{{ $t.getTranslation('LBL_ESTIMATED_ORDER_TAX') }}</h3>
            <h3>{{ helpers.formatPrice(Order.TotalOrderTax, Order.CurrencyCode) }}</h3>
          </div>

          <div class="content" v-if="Order.PlatformDiscount">
            <h3>{{ $t.getTranslation('LBL_PLATFORM_DISCOUNT') }}</h3>
            <h3>{{ helpers.formatPrice(Order.PlatformDiscount.Amount, Order.CurrencyCode) }}</h3>
          </div>

          <div class="content" v-if="Order.VoucherListAmount">
            <h3>{{ $t.getTranslation('LBL_COUPON') }}</h3>
            <h3>{{ helpers.formatPrice(Order.VoucherListAmount, Order.CurrencyCode) }}</h3>
          </div>

          <div class="content" v-if="Order.VoucherCode">
            <h3>{{ $t.getTranslation('LBL_COUPON_CODE') }}</h3>
            <h3>{{ helpers.formatPrice(Order.VoucherCode.Amount, Order.CurrencyCode) }}</h3>
          </div>

          <div class="content">
            <h3>{{ $t.getTranslation('LBL_GRAND_TOTAL') }}</h3>
            <h3>{{ helpers.formatPrice(Order.GrandTotal, Order.CurrencyCode) }}</h3>
          </div>
        </div>
      </f7-page-content>
    </f7-sheet>

    <f7-actions class="userAddressActions">
      <f7-actions-group>
        <f7-actions-button @click="editAdd">{{ $t.getTranslation('LBL_EDIT') }}</f7-actions-button>
        <f7-actions-button @click="deleteAdd">{{ $t.getTranslation('LBL_DELETE') }}</f7-actions-button>
      </f7-actions-group>
    </f7-actions>
  </f7-page>
</template>

<script>
import { configs } from '@/utils/configs'
import { defineComponent, computed, ref, onMounted, defineAsyncComponent } from 'vue'
import { post, get } from '@/utils/axios'
import { f7 } from 'framework7-vue'
import { helpers } from '@/utils/helpers.js'
import _ from 'lodash'
import { useStore } from '@/store'
import { processPayment } from '@/logics/payment'

// import ProductNavigationComponent from '@/components/navigations/ProductNavigationComponent.vue'

const ProductNavigationComponent = defineAsyncComponent(() => import(/* webpackChunkName: "product-nav" */ /* webpackMode: "lazy" */ "@/components/navigations/ProductNavigationComponent.vue"));

export default defineComponent({
  name: 'OrderCheckoutPage',
  components: {
    ProductNavigationComponent
  },
  props: { f7router: Object, f7route: Object },
  setup(props) {
    const store = useStore()
    
    let Cart = store.getters['cart/getData']

    const PaymentMethodListRaw = ref([])

    
    const PaymentMethodList = computed(()=>{
      let isMini = helpers.isMiniProgram()
      return _.filter(PaymentMethodListRaw.value || [], (r)=>{
        return (isMini && r.key == "WeChatPay_Acc" || !isMini)
      })
    })

    const PaymentMethod = ref(null)

    const actionUserAddressKey = ref(null)
    const UserAddressKey = ref(null)

    const Order = ref({})
    const Address = ref([])
    const SplitOrderCounter = ref([])

    const addAddress = async () => {
      props.f7router.navigate({ name: 'addressSettings' })
    }

    const orderCheckout = async () => {
      //if no selected payment then no split dont continue the process.
      if (SplitOrderCounter.value == 0 && !PaymentMethod.value) {
        return
      }

      let ret = await post('/order/checkout', { voucherlist: Cart.VOUCHER, vouchercode: Cart.VOUCHER_CODE, products: Cart.CHECKOUT_ITEMS, UserAddressKey: UserAddressKey.value, PaymentMethod: PaymentMethod.value })
      if (!ret) return

      if (ret?.SplitOrderCounter > 0) {
        //redirect to order list page..
        //window.location.href = `/profile/order/sub/?ParentOrderKey=${ret.OrderKey}`;
        let subOrders = await get('/order/list', { ParentOrderKey: ret.OrderKey })
        subOrders = subOrders.List
        subOrders = _.orderBy(subOrders, ['OrderId'], ['asc'])
        //navigate to page..
        props.f7router.navigate({ name: 'orderViewPage', params: { code: subOrders[0].OrderKey } })
        return
      }
      await processPayment(PaymentMethod.value, ret.OrderKey)
    }

    const openActions = async (add) => {
      actionUserAddressKey.value = add.UserAddressKey
      f7.sheet.get('.userAddressActions').open()
    }

    const openTotalInfo = () => {
      f7.sheet.get('.orderTotalInfo').open()
    }

    const editAdd = () => {
      props.f7router.navigate({ name: 'addressSettings', query: { key: actionUserAddressKey.value } })
    }

    const deleteAdd = async () => {
      let ret = await post('/user/address/remove', { key: actionUserAddressKey.value })
      if (!ret) return

      if (actionUserAddressKey.value == UserAddressKey.value) {
        UserAddressKey.value = Address.value[0].UserAddressKey
      }
      callCheck()
    }

    const selectAdd = (add) => {
      UserAddressKey.value = add.UserAddressKey
    }

    const selectPayment = (method) => {
      PaymentMethod.value = method
    }

    const callCheck = async () => {
      let ret = await get('/order/check', { voucherlist: Cart.VOUCHER, vouchercode: Cart.VOUCHER_CODE, products: Cart.CHECKOUT_ITEMS.join(','), method: 1, address: 1, items: 1 })
      if (!ret) return

      let isWechat = helpers.isInsideWeixin();
      if (ret.PaymentMethod && ret.PaymentMethod.length && isWechat) {
        ret.PaymentMethod = _.filter(ret.PaymentMethod, (r) => {
          return r.key != 'Alipay_Wap'
        })
      }

      PaymentMethodListRaw.value = ret.PaymentMethod || []
      if(PaymentMethodListRaw.value.length){
        PaymentMethod.value = PaymentMethodListRaw.value[0].key
      };


      Address.value = _.sortBy(ret.Address || [], (r) => {
        return r.IsDefault
      }).reverse()

      Order.value = ret.Order
      SplitOrderCounter.value = ret.Order.SplitOrderCounter

      let Default = _.find(ret.Address || [], (a) => {
        return a.IsDefault
      })

      if (Default && !UserAddressKey.value) {
        UserAddressKey.value = Default.UserAddressKey
      }
    }

    onMounted(callCheck)

    return {
      selectPayment,
      PaymentMethodList,
      PaymentMethod,
      editAdd,
      deleteAdd,
      selectAdd,
      UserAddressKey,
      helpers,
      Order,
      Address,
      addAddress,
      openActions,
      openTotalInfo,
      orderCheckout,
      SplitOrderCounter
    }
  }
})
</script>
